@import "tailwindcss/base";
/* ----- Custom base styles ----- */
@import "./base.css";

@import "tailwindcss/components";
/* ----- Custom components ----- */
@import "./components.css";

@import "tailwindcss/utilities";
/* ----- Custom utilities ----- */


/* Site CSS */

/* Header */
header {
    @apply font-medium;

    &.scrolled {
        &::before {
            content: '';
            @apply absolute top-0 x-center w-full h-full bg-white blur-3xl -z-1;
        }

        &::after {
            content: '';
            @apply absolute top-0 x-center w-full h-full bg-white blur-3xl -z-1;
        }
    }

    & nav a {
        @apply text-secondary lg:text-white;
    }

    &#header-content {
        & nav a {
            @apply lg:text-secondary hover:text-primary;
        }

    }

    & #megamenu {
        & a:not(.btn) {
            @apply hover:font-bold hover:text-primary transition-all block w-full;
        }
        & a.hidden {
            display: none;
        }
    }

    li.nav-active > a {
          @apply !text-primary;
    }
}

/* Contenu */
aside {
    @apply text-white;

    & li {
        @apply relative text-sm text-inherit pl-4 mb-2;

        & a {
            @apply opacity-0;

            &.active {
                @apply opacity-100;
            }
        }

        &:hover a {
            @apply opacity-100;
        }

        &::before {
            content: '';
            @apply absolute y-center left-0 w-2 h-2 border border-inherit rounded-full;
        }
    }
}

#slider-hero {
    & .swiper-slide::before {
        content: '';
        @apply absolute left-0 top-0 w-full h-full bg-black bg-opacity-60 z-1;
    }
}

#panels {
    & > div {
        &:nth-of-type(even) {
            & .panel-categorie {
                & .cat-image {
                    @apply left-0;
                }

                & .cat-desc {
                    @apply ml-auto pr-48 pl-8 xl:pl-12 xxl:pl-16;
                }

                & .swiper-produit_next {
                    @apply -right-16;
                }

                & .swiper-produit_prev {
                    @apply -left-16;
                }

                & .swiper-produit_next.swiper-button-disabled,
                & .swiper-produit_prev.swiper-button-disabled {
                    @apply opacity-25;
                }
            }
        }

        &:nth-of-type(odd) {
            & .panel-categorie {
                & .cat-image {
                    @apply right-0;
                }

                & .cat-desc {
                    @apply mr-auto pl-48 pr-8 xl:pr-12 xxl:pr-16;
                }

                & .swiper-produit_next {
                    @apply right-initial -left-16 rotate-180 translate-y-1/2;
                }

                & .swiper-produit_prev {
                    @apply left-initial -right-16 rotate-180 translate-y-1/2;
                }
            }
        }
    }

    & .cat-image {
        &::before {
            content: '';
            @apply absolute left-0 top-0 w-full h-full bg-black bg-opacity-40 z-1;
        }
    }
}

.produit {
    &-img::before {
        content: '';
        @apply absolute left-0 top-0 w-full h-full bg-primary opacity-0;
    }

    &:hover {
        & .produit-img::before {
            @apply opacity-50;
        }
    }
}

#filter-categorie {
    & input {
        @apply absolute h-0 w-0 opacity-0 invisible;
    }

    & .categorie {
        @apply bg-primary text-white text-sm lg:text-base py-2 px-4 rounded-full cursor-pointer transition-all;
    }
}

#actualites {
    @apply relative;

    &::before {
        content: '';
        @apply absolute left-0 top-0 w-full h-full bg-black bg-opacity-60 z-1;
    }
}

#content {
    @apply relative w-11/12 xl:w-3/4 mx-auto py-12 lg:py-24;

    & h1 {
        @apply text-4xl lg:text-5xl mb-8;
    }

    & h2 {
        @apply text-3xl lg:text-4xl mb-4 mt-8;
    }

    & h2 {
        @apply text-2xl lg:text-3xl mb-4;
    }

    & ul {
        @apply list-disc list-inside mb-4;
    }

    & a {
        @apply text-primary hover:text-primary-700;
    }
}

/* Footer */
footer {
    & h3 {
        @apply text-xl font-bold mb-4;
    }

    & a {
        @apply hover:text-secondary;
    }
}

#hero::before {
    content: '';
    @apply absolute left-0 top-0 w-full h-full bg-secondary-900 bg-opacity-60 z-1;

}

#services {
    & div {
        @apply opacity-0;
        & img {
            @apply h-16 mb-2;
        }
        & p {
            @apply font-bold text-xl;
        }
    }
}

/* Produits */
.fil {
    @apply relative flex flex-wrap text-xs text-grey-500 font-medium;

    & a {
        @apply text-grey-500 hover:text-primary;
    }

    & span {
        @apply mx-2;
    }
}

#slider-produit-thumb {
    & .swiper-slide {
        @apply border-2 border-transparent;

        &-active {
            @apply border-primary;
        }
    }
}

#produit {
    &-bg::before {
        content: '';
        @apply absolute left-0 top-0 w-full h-full bg-secondary-900 bg-opacity-60 z-1;
    }

    &-description {
        & ul {
            @apply list-disc list-inside mt-2;
        }
    }

    &-caracteristiques {
        & ul {
            @apply list-disc list-inside mt-2;
        }

        tbody {
            @apply block columns-1 lg:columns-2;
        }
        tr {
            @apply break-inside-avoid mb-4;
        }

    }

    &-utilisation {
        & ul {
            @apply mb-8;

            & li {
                @apply relative pl-5 mb-2;

                &::before {
                    content: '';
                    @apply absolute left-0 y-center w-3 h-3 bg-transparent border-2 border-primary rounded-full;
                }

            }
        }
    }


    &-accessoires {
        overflow-x: auto;

        & table {
            @apply table w-full shadow-lg text-xs lg:text-base;
            min-width: max-content;

            tr, td {
                height: auto!important;
            }

            & tr:first-of-type {
                @apply justify-between bg-grey-200;
                td {
                    @apply font-bold text-center px-1 xl:py-6;
                    /*@apply w-45p font-bold text-center;*/
                }
            }

            & tr:not(:first-of-type) {
                @apply justify-between items-center;
                td {
                    @apply text-center px-1 xl:py-4;
                    /*@apply w-45p text-center;*/
                }
            }

            & tr:not(:first-of-type):nth-of-type(2n) {
                @apply bg-secondary-200;
            }

        }
    }

    &-autres {
        &::before {
            content: '';
            @apply absolute left-0 top-0 w-full h-full bg-secondary-900 bg-opacity-80 z-1;
        }
    }
}

.produit-titre-container {
    & h2 {
        @apply text-3xl sm:text-4xl lg:text-5xl mb-8;
    }
}

.table {
    & p {
        @apply p-0 mb-0;
    }
}

/* Form */
form {
    & .form-input {
        @apply flex flex-col mb-4;

        & label {
            @apply text-primary font-bold text-lg mb-1;
        }

        & input:not([type="submit"]), select, textarea {
            @apply w-full py-1 px-2 border border-primary bg-white font-bold text-secondary;
        }
    }
}

/* Responsive */
@screen sm {
}
@screen md {
}
@screen lg {
}
@screen xl {
}
@screen xxl {
}

.filtre-marque {
    .pin {
        @apply absolute;
    }
}

.peer:checked ~ .filtre-marque {
    & > img {
        @apply opacity-100;
    }
    .pin {
        @apply bg-red;

        img:first-of-type {
            @apply hidden;
        }
        img:last-of-type {
            @apply block;
        }
    }
}



.form-sheet{

    & label{
        @apply !mb-0 mx-1;
    }


    & .template-rgpd{
        @apply !text-xs;
    }
}


.radio-option, .checkbox-option{

   & input:not([type="submit"]){
       width: initial !important;
   }


}