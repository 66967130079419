/* ----- Fonts ----- */

/* Style de base */
body {
    @apply font-body overflow-x-hidden;
    scroll-behavior: smooth;
}
h1, h2, h3 {
    @apply font-heading font-bold;
}
h2 {
    @apply text-primary;
}
a, button, input[type="submit"], *::before, *::after {
    @apply transition-all;
}
img {
    @apply inline-block;
}

main {
    & p {
        @apply font-regular mb-2;
    }
}

/* Menu et sous-menu */
nav ul li {
    @apply flex-grow relative px-2 py-1 text-secondary-900;
    &:not(.rs) > a {
        @apply relative text-center hover:font-bold;
    }
    & > ul {
        @apply w-max hidden;
    }
}

@screen lg {
    nav ul li {
        & > ul {
            @apply absolute block opacity-0 invisible transition-all;

            & li > ul {
                @apply top-0 left-full;
            }
        }
        &:hover > ul {
            @apply opacity-100 visible;
        }
    }
}
